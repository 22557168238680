import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./style.scss"

const MobileLogo = ({ children }) => {
  const data = useStaticQuery(graphql`
    query GetMobileLogo {
      file(relativePath: { eq: "Logo2020_432x188.png" }) {
        childImageSharp {
          fixed(width: 216, height: 94) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="Rysso Law, PLLC Logo"
      className="is-hidden-tablet"
    />
  )
}

export default MobileLogo
