import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Popup from "reactjs-popup"

import BurgerIcon from "./BurgerIcon"
import MobileMenu from "./MobileMenu"
import MobileLogo from "./MobileLogo"
import "./style.scss"
import Helmet from "./helmet"
// import Header from './header';
import Sidebar from "./sidebar"
import SidebarFooter from "./SidebarFooter"
// import Footer from './footer';

const contentStyle = {
  background: "rgba(255,255,255,0)",
  width: "80%",
  border: "none",
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query GetLogo {
      file(relativePath: { eq: "Logo2020_704x134.png" }) {
        childImageSharp {
          fluid(maxWidth: 352, maxHeight: 67) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet />
      {/* <Header /> */}
      <div className="columns">
        <div
          className="column is-hidden-desktop"
          style={{ "border-top": "solid 1.5rem #A42E2D" }}
        ></div>
        <div className="column is-one-third is-hidden-touch solidBg">
          <Sidebar />
        </div>
        <div className="column main-content">
          <div className="container is-fluid">
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Rysso Law, PLLC Logo"
              className="is-hidden-touch"
              style={{ maxWidth: "352px" }}
            />
            <div className="is-hidden-desktop">
              <MobileLogo />
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="Rysso Law, PLLC Logo"
                className="is-hidden-mobile"
                style={{ maxWidth: "352px" }}
              />
              <Popup
                modal
                overlayStyle={{ background: "#A42E2D" }}
                contentStyle={contentStyle}
                closeOnDocumentClick={false}
                trigger={open => <BurgerIcon open={open} />}
              >
                {close => <MobileMenu close={close} />}
              </Popup>
            </div>
          </div>
          {children}
        </div>
      </div>
      {/* <Footer /> */}
      <div className="column is-hidden-desktop solidBg">
        <SidebarFooter />
      </div>
    </div>
  )
}

export default Layout
