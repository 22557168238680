import React from "react";

import SidebarLink from "./SidebarLink";
import "./style.scss";

const SidebarNav = () => (
  <aside className="menu has-text-right">
    <ul className="menu-list">
      <SidebarLink to="/" label="HOME" />
      <SidebarLink to="/about-us/" label="ABOUT US" />
      <SidebarLink to="/contact-us/" label="CONTACT US" />
      <SidebarLink
        to="/what-is-elder-law-medicaid-planning/"
        label="WHAT IS ELDER LAW &#038;"
        label2="MEDICAID PLANNING?"
      />
      <SidebarLink
        to="/estate-planning-probate-faqs/"
        label="ESTATE PLANNING &#038;"
        label2="PROBATE FAQS"
      />
      <SidebarLink
        to="/the-lady-bird-deed/"
        label="THE &#8220;LADY BIRD&#8221; DEED"
      />
      <SidebarLink
        to="/revocable-living-trusts/"
        label="REVOCABLE LIVING TRUSTS"
      />
      <SidebarLink to="/special-needs-trusts/" label="SPECIAL NEEDS TRUSTS" />
      <SidebarLink to="/what-is-probate/" label="WHAT IS PROBATE?" />
      <SidebarLink
        to="/guardians-conservators/"
        label="GUARDIANS &#038; CONSERVATORS"
      />
      <SidebarLink
        to="/michigan-estate-recovery/"
        label="MICHIGAN ESTATE RECOVERY"
      />
    </ul>
  </aside>
);

export default SidebarNav;
