import React from "react"

import MobileLink from "./MobileLink"

export default ({ close }) => (
  <div className="menu">
    <ul>
      <MobileLink to="/" label="HOME" />
      <MobileLink to="/about-us/" label="ABOUT US" />
      <MobileLink to="/contact-us/" label="CONTACT US" />
      <MobileLink
        to="/what-is-elder-law-medicaid-planning/"
        label="WHAT IS ELDER LAW &#038;"
        label2="MEDICAID PLANNING?"
      />
      <MobileLink
        to="/estate-planning-probate-faqs/"
        label="ESTATE PLANNING &#038;"
        label2="PROBATE FAQS"
      />
      <MobileLink
        to="/the-lady-bird-deed/"
        label="THE &#8220;LADY BIRD&#8221; DEED"
      />
      <MobileLink
        to="/revocable-living-trusts/"
        label="REVOCABLE LIVING TRUSTS"
      />
      <MobileLink to="/special-needs-trusts/" label="SPECIAL NEEDS TRUSTS" />
      <MobileLink to="/what-is-probate/" label="WHAT IS PROBATE?" />
      <MobileLink
        to="/guardians-conservators/"
        label="GUARDIANS &#038; CONSERVATORS"
      />
      <MobileLink
        to="/michigan-estate-recovery/"
        label="MICHIGAN ESTATE RECOVERY"
      />
    </ul>
  </div>
)
