import React from "react";

import { Link } from "gatsby";

import "./style.scss";

const SidebarLink = ({ to, label, label2 }) => {
  let line2;
  if (label2) {
    line2 = (
      <span>
        <br />
        {label2}
      </span>
    );
  }

  return (
    <li className="sidebarlink">
      <Link
        to={to}
        className="has-text-white is-size-5"
        activeClassName="active"
      >
        {label}
        {line2}
      </Link>
    </li>
  );
};

export default SidebarLink;
