import React from "react"
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa"

import AvvoLogo from "./AvvoLogo"
import SuperLawyersLogo from "./SuperLawyersLogo"
import "./style.scss"

const SidebarFooter = () => {
  return (
    <div className="has-text-white">
      <span className="has-text-weight-bold">RYSSO LAW, PLLC</span>
      <br />
      Elder Law &#038;
      <br />
      Special Needs Attorneys
      <br />
      1004 East 8th Street
      <br />
      Traverse City, MI 49686
      <br />
      <a
        href="tel:231-649-2903"
        rel="noopener noreferrer"
        className="phone-link"
      >
        231-649-2903
      </a>{" "}
      CALL or TEXT
      <br />
      <a
        href="https://www.facebook.com/RyssoandWingfield/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookSquare size="2em" color="white" />
      </a>
      <a
        href="https://www.linkedin.com/in/deborah-rysso-204a7811"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin size="2em" color="white" />
      </a>
      <br />
      <br />
      <div class="columns">
        <div class="column">
          <SuperLawyersLogo />
        </div>
        <div class="column">
          <AvvoLogo />
        </div>
      </div>
    </div>
  )
}

export default SidebarFooter
