import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./style.scss"

const SuperLawyersLogo = ({ children }) => {
  const data = useStaticQuery(graphql`
    query GetSuperLawyersLogo {
      file(relativePath: { eq: "SuperLawyersBadgeSquareWhite.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 209) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a
      href="https://www.superlawyers.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="AVVO Rating - Superb - Top Attorney Estate Planning"
      />
    </a>
  )
}

export default SuperLawyersLogo
