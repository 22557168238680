import React from "react"

import SidebarNav from "./SidebarNav"
import SidebarFooter from "./SidebarFooter"
import "./style.scss"

const Sidebar = () => (
  <div className="sidebar-container" style={{ width: "100%" }}>
    <div style={{ padding: "0 0 3rem 0" }}>
      <SidebarNav />
    </div>
    <SidebarFooter />
  </div>
)

export default Sidebar
