import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./style.scss"

const AvvoLogo = ({ children }) => {
  const data = useStaticQuery(graphql`
    query GetAvvoLogo {
      file(relativePath: { eq: "AVVO.png" }) {
        childImageSharp {
          fixed(width: 153, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a
      href="https://www.avvo.com/attorneys/49684-mi-deborah-rysso-758490.html?utm_campaign=avvo_rating&utm_content=1217583&utm_medium=avvo_badge&utm_source=avvo"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="AVVO Rating - Superb - Top Attorney Estate Planning"
      />
    </a>
  )
}

export default AvvoLogo
